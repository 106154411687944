html, body, #root, #root > div {
  height: 100%;
  min-height: 100%;
  margin: 0rem;
  padding: 0rem;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

button, input, optgroup, select, textarea {
  -webkit-appearance: none;
  outline: none;
}

input:-internal-autofill-selected {
  color: none !important;
}

button:focus {
  outline: 0 !important;
}
