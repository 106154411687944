@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
.light-theme {
  --light-gray: var(--chakra-colors-gray-200);
  --gray: var(--chakra-colors-gray-300);
  --teal700: var(--chakra-colors-gray-600);
  --teal600: var(--chakra-colors-gray-500);
  --teal500: var(--chakra-colors-gray-400);
  --teal400: var(--chakra-colors-gray-300);
  --teal300: var(--chakra-colors-gray-200);
  --teal200: var(--chakra-colors-gray-200);
  --teal100: var(--chakra-colors-gray-100);
  --monthBackground: var(--chakra-colors-white);
  --text: var(--chakra-colors-gray-600);
  --negative-text: var(--chakra-colors-white);
}

.dark-theme {
  --light-gray: var(--chakra-colors-gray-600);
  --gray: var(--chakra-colors-gray-500);
  --teal700: var(--chakra-colors-teal-600);
  --teal600: var(--chakra-colors-teal-300);
  --teal500: var(--chakra-colors-gray-500);
  --teal400: var(--chakra-colors-gray-600);
  --teal300: var(--chakra-colors-gray-700);
  --teal200: var(--chakra-colors-gray-600);
  --teal100: var(--chakra-colors-gray-800);
  --monthBackground: var(--chakra-colors-gray-700);
  --text: var(--chakra-colors-gray-200);
  --negative-text: var(--chakra-colors-black);
}

.react-datepicker {
  font-family: unset;
  font-size: 0.9rem;
  border-color: var(--light-gray);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container {
  font-size: 14px;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 40px;
  border-radius: 0.45rem;
  min-width: 125px;
  border: 1px solid;
  color: var(--text);
  border-color: var(--light-gray);
}

.react-datapicker__input-text {
  background-color: transparent;
}

.react-datepicker__input-container:hover {
  border-color: var(--gray)
}

.react-datepicker__input-container:focus-within {
  z-index: 1;
  border-color: var(--teal600);
  box-shadow: 0 0 0 1px var(--teal600);
}

.react-datepicker__input-container>input {
  width: 100%;
  height: 100%;
  outline: 0;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 42px;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--teal400);
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--teal500);
}

.react-datepicker__navigation--next {
  border-left-color: var(--teal400);

}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--teal500);
}

.react-datepicker__header {
  background-color: var(--teal100);
}

.react-datepicker__header:active {
  background-color: var(--teal100);
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--teal300);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--text)
}

.react-datepicker__month {
  background-color: var(--monthBackground);
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background: var(--teal200);
}

.react-datepicker__day {
  color: var(--text)
}

.react-datepicker__day:hover {
  background: var(--teal200);
}

.react-datepicker__day-name {
  color: var(--text)
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: var(--teal600);
  font-weight: normal;
  color: var(--negative-text);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background: var(--teal700);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range) {
  background-color: var(--teal400);
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--light-gray);
  height: 20px;
  width: 20px;
}

.react-datepicker__day--outside-month {
  color: var(--gray);
}

.react-datepicker__close-icon::after:hover {
  color: var(--gray)
}


.time,
.time-wrapper,
.time-wrapper>div {
  min-width: 75px;
  width: auto;
  max-width: 50px;
  height: 40px !important;
  border-radius: 10px;
}

.time {
  height: 38px !important;
  width: 50px;
  background-color: transparent !important;
}


.specialError {
  color: #F56565;
  font-size: 14px;
  display: block;
  font-family: "Raleway-Regular";
  font-style: italic;
  max-width: 125px;
}
html,body,#root,#root>div{height:100%;min-height:100%;margin:0rem;padding:0rem}.poppins-regular{font-family:"Poppins",sans-serif;font-weight:400;font-style:normal}.poppins-semibold{font-family:"Poppins",sans-serif;font-weight:600;font-style:normal}input:focus,textarea:focus,select:focus{outline:none}button,input,optgroup,select,textarea{-webkit-appearance:none;outline:none}input:-internal-autofill-selected{color:none !important}button:focus{outline:0 !important}
